button {
    outline: none;
}

.big-button{
    color: white;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    background-color: var(--main-button-color);

    opacity: 1;
    padding: 10px 40px 10px 40px;

    border: none;
    border-radius: 6px;
}

.big-button:hover{
    cursor: pointer;
    color: white;
    background-color: var(--main-button-color);
    box-shadow: 0px 3px 0px var(--db3d-torkoise-dark-color);
}

.big-button:active{
    background-color: var(--main-button-color);
    box-shadow: 0px 3px 0px var(--db3d-torkoise-color);
}

.big-button:disabled {
    background-color: var(--db3d-grey-color);
    box-shadow: none;
    color: white;
    opacity: 1;
}

.big-button.disabled {
  background-color: var(--db3d-grey-color);
  box-shadow: none;
  color: white;
  opacity: 1;
  cursor: wait;
}
