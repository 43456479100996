.p-0{
    padding: 0px;
}

.p-30 {
    padding: 30px;
}

.p-l-0{
    padding-left: 0;
}

.p-r-0{
    padding-right: 0;
}

.p-5{
    padding: 5px;
}

.m-0{
    margin: 0px;
}

.m-l-0{
    margin-left: 0px;
}

.m-5{
    margin: 5px;
}

.m-t-10{
    margin-top: 10px;
}

.m-t-15{
    margin-top: 10px;
}