.owl-carousel .owl-wrapper:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

.owl-carousel {
    display: none;
    position: relative;
    width: 100%;
    -ms-touch-action: pan-y;
}

    .owl-carousel .owl-wrapper {
        display: none;
        position: relative;
        -webkit-transform: translate3d(0,0,0);
    }

    .owl-carousel .owl-wrapper-outer {
        overflow: hidden;
        position: relative;
        width: 100%;
    }

        .owl-carousel .owl-wrapper-outer.autoHeight {
            -webkit-transition: height 500ms ease-in-out;
            -moz-transition: height 500ms ease-in-out;
            -ms-transition: height 500ms ease-in-out;
            -o-transition: height 500ms ease-in-out;
            transition: height 500ms ease-in-out;
        }

    .owl-carousel .owl-item {
        float: left;
    }

.owl-controls .owl-page, .owl-controls .owl-buttons div {
    cursor: pointer;
}

.owl-controls {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.grabbing {
    cursor: url(img/grabbing.png) 8 8,move;
}

.owl-carousel .owl-wrapper, .owl-carousel .owl-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0,0,0);
    -moz-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
}

    .owl-carousel .owl-wrapper, .owl-carousel .owl-item img {
        width: 100%;
        height: auto;
    }


.owl-theme .owl-controls {
    margin-top: 0;
    text-align: center;
}

    .owl-theme .owl-controls .owl-buttons div {
        position: absolute;
        top: 50%;
        right: auto;
        left: auto;
        background-repeat: no-repeat;
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }

        .owl-theme .owl-controls .owl-buttons div:hover span {
            text-decoration: none;
            -moz-transform: scale(1.2);
            -ms-transform: scale(1.2);
            -o-transform: scale(1.2);
            -webkit-transform: scale(1.2);
            transform: scale(1.2);
        }

        .owl-theme .owl-controls .owl-buttons div span {
            -moz-transition: all .3s;
            -o-transition: all .3s;
            -webkit-transition: all .3s;
            transition: all .3s;
            width: 50px;
            height: 50px;
            line-height: 50px;
            display: block;
            font-size: 20px;
            color: #fff;
            border-radius: 100%;
            text-align: center;
        }

    .owl-theme .owl-controls .owl-buttons .owl-prev {
        left: 10px;
    }

    .owl-theme .owl-controls .owl-buttons .owl-next {
        right: 10px;
    }

    .owl-theme .owl-controls .owl-buttons {
        opacity: 0;
        -moz-transition: all 0.5s;
        -o-transition: all 0.5s;
        -webkit-transition: all 0.5s;
        transition: all 0.5s;
    }

.owl-theme:hover .owl-controls .owl-buttons {
    opacity: 1;
}

.owl-theme .owl-controls .owl-pagination {
    position: absolute;
    width: 100%;
    bottom: 0;
}

.owl-theme .owl-controls .owl-page {
    display: inline-block;
    zoom: 1;
    *display: inline;
}

    .owl-theme .owl-controls .owl-page span {
        display: block;
        width: 5px;
        height: 5px;
        margin: 5px;
        opacity: .5;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
        background: #828282;
    }

    .owl-theme .owl-controls .owl-page.active span, .owl-theme .owl-controls.clickable .owl-page:hover span {
        opacity: 1;
    }

    .owl-theme .owl-controls .owl-page span.owl-numbers {
        height: auto;
        width: auto;
        color: #fff;
        padding: 2px 10px;
        font-size: 12px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        border-radius: 30px;
    }

.owl-item.loading {
    min-height: 150px;
    background: url(img/loader.gif) no-repeat center center;
}

.owl-origin {
    -webkit-perspective: 1200px;
    -webkit-perspective-origin-x: 50%;
    -webkit-perspective-origin-y: 50%;
    -moz-perspective: 1200px;
    -moz-perspective-origin-x: 50%;
    -moz-perspective-origin-y: 50%;
    perspective: 1200px;
}

.owl-fade-out {
    z-index: 10;
    -webkit-animation: fadeOut .7s both ease;
    -moz-animation: fadeOut .7s both ease;
    animation: fadeOut .7s both ease;
}

.owl-fade-in {
    -webkit-animation: fadeIn .7s both ease;
    -moz-animation: fadeIn .7s both ease;
    animation: fadeIn .7s both ease;
}

.owl-backSlide-out {
    -webkit-animation: backSlideOut 1s both ease;
    -moz-animation: backSlideOut 1s both ease;
    animation: backSlideOut 1s both ease;
}

.owl-backSlide-in {
    -webkit-animation: backSlideIn 1s both ease;
    -moz-animation: backSlideIn 1s both ease;
    animation: backSlideIn 1s both ease;
}

.owl-goDown-out {
    -webkit-animation: scaleToFade .7s ease both;
    -moz-animation: scaleToFade .7s ease both;
    animation: scaleToFade .7s ease both;
}

.owl-goDown-in {
    -webkit-animation: goDown .6s ease both;
    -moz-animation: goDown .6s ease both;
    animation: goDown .6s ease both;
}

.owl-fadeUp-in {
    -webkit-animation: scaleUpFrom .5s ease both;
    -moz-animation: scaleUpFrom .5s ease both;
    animation: scaleUpFrom .5s ease both;
}

.owl-fadeUp-out {
    -webkit-animation: scaleUpTo .5s ease both;
    -moz-animation: scaleUpTo .5s ease both;
    animation: scaleUpTo .5s ease both;
}

@-webkit-keyframes empty {
    0% {
        opacity: 1;
    }
}

@-moz-keyframes empty {
    0% {
        opacity: 1;
    }
}

@keyframes empty {
    0% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-moz-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@-webkit-keyframes backSlideOut {
    25% {
        opacity: .5;
        -webkit-transform: translateZ(-500px);
    }

    75% {
        opacity: .5;
        -webkit-transform: translateZ(-500px) translateX(-200%);
    }

    100% {
        opacity: .5;
        -webkit-transform: translateZ(-500px) translateX(-200%);
    }
}

@-moz-keyframes backSlideOut {
    25% {
        opacity: .5;
        -moz-transform: translateZ(-500px);
    }

    75% {
        opacity: .5;
        -moz-transform: translateZ(-500px) translateX(-200%);
    }

    100% {
        opacity: .5;
        -moz-transform: translateZ(-500px) translateX(-200%);
    }
}

@keyframes backSlideOut {
    25% {
        opacity: .5;
        transform: translateZ(-500px);
    }

    75% {
        opacity: .5;
        transform: translateZ(-500px) translateX(-200%);
    }

    100% {
        opacity: .5;
        transform: translateZ(-500px) translateX(-200%);
    }
}

@-webkit-keyframes backSlideIn {
    0%,25% {
        opacity: .5;
        -webkit-transform: translateZ(-500px) translateX(200%);
    }

    75% {
        opacity: .5;
        -webkit-transform: translateZ(-500px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateZ(0) translateX(0);
    }
}

@-moz-keyframes backSlideIn {
    0%,25% {
        opacity: .5;
        -moz-transform: translateZ(-500px) translateX(200%);
    }

    75% {
        opacity: .5;
        -moz-transform: translateZ(-500px);
    }

    100% {
        opacity: 1;
        -moz-transform: translateZ(0) translateX(0);
    }
}

@keyframes backSlideIn {
    0%,25% {
        opacity: .5;
        transform: translateZ(-500px) translateX(200%);
    }

    75% {
        opacity: .5;
        transform: translateZ(-500px);
    }

    100% {
        opacity: 1;
        transform: translateZ(0) translateX(0);
    }
}

@-webkit-keyframes scaleToFade {
    to {
        opacity: 0;
        -webkit-transform: scale(.8);
    }
}

@-moz-keyframes scaleToFade {
    to {
        opacity: 0;
        -moz-transform: scale(.8);
    }
}

@keyframes scaleToFade {
    to {
        opacity: 0;
        transform: scale(.8);
    }
}

@-webkit-keyframes goDown {
    from {
        -webkit-transform: translateY(-100%);
    }
}

@-moz-keyframes goDown {
    from {
        -moz-transform: translateY(-100%);
    }
}

@keyframes goDown {
    from {
        transform: translateY(-100%);
    }
}

@-webkit-keyframes scaleUpFrom {
    from {
        opacity: 0;
        -webkit-transform: scale(1.5);
    }
}

@-moz-keyframes scaleUpFrom {
    from {
        opacity: 0;
        -moz-transform: scale(1.5);
    }
}

@keyframes scaleUpFrom {
    from {
        opacity: 0;
        transform: scale(1.5);
    }
}

@-webkit-keyframes scaleUpTo {
    to {
        opacity: 0;
        -webkit-transform: scale(1.5);
    }
}

@-moz-keyframes scaleUpTo {
    to {
        opacity: 0;
        -moz-transform: scale(1.5);
    }
}

@keyframes scaleUpTo {
    to {
        opacity: 0;
        transform: scale(1.5);
    }
}
