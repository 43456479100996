/* Global variables */
@import "../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";

@import "./assets/css/schemes/buttons.scss";



:root {
    --db3d-red-color: #D7263D;
    --db3d-red-accent-color: #FF2D2D;
    --db3d-torkoise-dark-color: #0D6175;
    --db3d-torkoise-color: #44ADAD;
    --db3d-torkoise-light-color: #A2E8DD;

    --db3d-light-grey-color: #c5c5c5;
    --db3d-grey-color: #999999;
    --db3d-dark-grey-color: #4d4d4d;
    --db3d-dashed-line-color: #999999;


    --main-color: #44ADAD;

    --product-icon-color: #A2E8DD;
    --tooltip-text-color: #0D6175;

    --main-text-color: #44ADAD;
    --sec-text-color: #18A0FB;
    --dark-grey-text-color: #494949;
    --text-hover-color: #8e8e8e;

    --main-hover-color: #8e8e8e;

    --lines-main-color: #c5c5c5;
    --lines-secondary-color: #f0f0f0;


    --btn-color-main: #8e8e8e;
    --btn-color-main-hover: #44ADAD;
    --main-button-hover-dark-grey: #8e8e8e;


    --btn-color-secondary: #c5c5c5;
    --btn-color-secondary-hover: #44ADAD;
    --btn-border-color: #cccccc;

    --border-color-main: #efefef;

    --main-bg-color: #ffffff;

    --light-grey-color: #c5c5c5;
    --extra-light-grey-color: #f0f0f0;
    --dark-grey-color: #494949;
    --pink-color: #eb7694;

    /* Mobile menu colors */
    --mobile-separator-line-color: #ffffff;
    --mobile-menu-background-color: #ffffff;
    --mobile-menu-icons-color: #494949;
    --mobile-menu-text-color: #494949;
    --mobile-menu-text-hover-color: #44ADAD;

    /* Top nav bar */
    --top-navbar-bg-color: #ffffff;
    --icon-hover-color: #44ADAD;

    /* Product Filters */
    --filter-arrow-opened-color: #494949;
    --filter-arrow-closed-color: #8e8e8e;

    /* Footer */
    --footer-background-color: #ffffff;

    /* Product list */
    --product-hover-border-color: #8e8e8e;
    --filters-border-color: #efefef;

    /* Forms */
    --input-focus-shadow-color: #8e8e8e;

    /* Pagination */
    --inactive-button-color: #ffffff;
    --inactive-button-text-color: #c5c5c5;
    --inactive-button-border-color: #c5c5c5;

    --active-button-color: #ffffff;
    --active-button-text-color: #44ADAD;
    --active-button-border-color: #44ADAD;
}

.not-displayed{
    display: none;
}

.no-outline, .no-outline:hover, .no-outline:visited, .no-outline:active{
    outline: none !important;
}

.narrow-content-wrapper{
    background-color: var(--main-bg-color);
    min-height: 440px;
}

.narrow-content-wrapper {
    background-color: var(--main-bg-color);
    background-image: url(/assets/images/full-page-bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 5vh;
    min-height: 90vh;
}

@media (min-width: 992px) {
    .narrow-content-wrapper {
        padding-top: 5vh;
    }
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@media (max-width: 1210px) {
    us-button{
        position: absolute;
        transform: rotate(90deg) translateX(-50%);
        right: 50%;
        top: 100wh;
    }
}

@media (max-width: 770px) {
    .feedback-button-left {
        us-button{
            position: absolute;
            transform: translateY(0);
            right: 0;
            z-index: 10000;
            top: 20%;
        }
    }
}

/* Desable SiloShoot button in 360 player */
#intiaroPlayer_intiaro-configurator_interialViewerSiloShootWidget{
    display: none !important;
}


.intiaro360-full-screen {
    #product-configurator{
        height: 100vh;
    }
    .intiaro360player{
        z-index: 10000;
    }
}

.product_design_preview_contariner #product-design-configurator #viewerMeasurementButton,
.product_design_preview_contariner #product-design-configurator .help-text,
.product_design_preview_contariner #product-design-configurator .full-screen-btn{
    display: none !important;
}

.product_design_preview_contariner .intiaro360player{
    box-shadow:none !important;
}

/*custom colors*/
:root {
    // --main-button-color: #44adad;     
    // --secondary-button-color: #539BAD;
    // --highlight-text-color: #44adad;
    // --mobile-menu-background: #0d6175;
    // --configurator-color-group: #0d6175;//no use yet
}