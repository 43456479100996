/* Scrollbar */
.mat-dialog-container::-webkit-scrollbar-track
{
box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
background-color: #F5F5F5;
}

.mat-dialog-container::-webkit-scrollbar
{
width: 6px;
background-color: #F5F5F5;
}

.mat-dialog-container::-webkit-scrollbar-thumb
{
background-color: var(--db3d-torkoise-color);
}
/* Scrollbar finish */


.cdk-overlay-container{
    z-index: 9999;
}

.cdk-overlay-container .cdk-overlay-pane {
    background-color: #fff;
    border-radius: 6px;
}

.db3d-popup-wrapper {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 16px;
    overflow: auto;
}
.db3d-popup-wrapper mat-dialog-container.mat-dialog-container{
    background-color: white;
    color: var(--db3d-dark-grey-color);
}


.db3d-notification-popup-wrapper {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 16px;
    max-height: 500px;
}
.db3d-notification-popup-wrapper mat-dialog-container.mat-dialog-container{
    background-color: white;
    color: var(--db3d-dark-grey-color);
    border: 1px dashed var(--db3d-dashed-line-color);
    padding: 0px 20px;
    position: relative;
}

.db3d-transparent-backdrop {
    opacity: 0;
}

.close-button {
    opacity: 1;
    float: right;
    font-size: 13px;
    color: var(--db3d-dark-grey-color);
    cursor: pointer;
    background-color: white;
    border: none;
    outline: none;
}

.close-button:disabled {
    opacity: 0;
}

.close-icon {
    font-weight: 700;
}

.logo {
    display: inline-block;
    width: 100%;
    text-align: center;
}

.header {
    display: inline-block;
    width: 100%;
    font-size: 35px;
    text-align: center;
}

.header-bold {
    font-weight: 700;
}
